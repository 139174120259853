/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ==================================x====================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  	var Sage = {
    	// All pages
    	'common': {
      		init: function() {
        		
      			$(document).ready(function(){

      				if(navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
	  				{
	  					console.log("Safari / iPad browser!");
	  					//$(".navbar-wrapper").removeClass("col-xs-6").addClass("col-xs-8");
	  					$("head").append("<link rel='stylesheet' href='/wp-content/themes/coco/assets/styles/safari.css' type='text/css' />");
	  				}

	  				var newsletterModal = ouibounce(document.querySelector(".newsletter-modal"), {
	  					cookieExpire : 7,
	  					callback : function(){
	  						$(".newsletter-modal").toggleClass("showing");
      						$(".newsletter-modal .box").toggleClass("come-up");
	  					}
	  				});

	  				window.setTimeout(function(){
	  					newsletterModal.fire();
	  				}, 7500);

      				if( window.canRunAds === undefined ){
				        // adblocker detected
				        $(".feed-article").last().css({"margin-bottom" : "0px"});
				    }

				    //$(window).load(function(){
						window.setTimeout(function(){
	  						$(".page-loader").fadeOut(75);
	  						$(".bx-loading").each(function(index, el){
	  							$(el).hide();
	  						});
	  					}, 275);
					//});

					console.log("Calling setup.");
					plyr.setup();

				    $("input[type=checkbox]").each(function(index, el){
				    	$(el).parent().addClass("checkbox checkbox-circle");
				    });

				    $(document).bind('gform_post_render', function(){
				        $("input[type=checkbox]").each(function(index, el){
					    	$(el).parent().addClass("checkbox checkbox-circle");
					    });
				    });

				    if(window.innerWidth < 992){
				    	$("body").on('click', '.article-inline-image', function(e){
				    		$(this).toggleClass("show-overlay");
				    	});

				    	$("body").on('click', '.image-container', function(e){
				    		$(this).toggleClass("show-overlay");
				    	});
				    }

				    var currentURL = window.location.href;

				    $(".article-content img").not('.article-content-section img').each(function(index, el){
				    	var toAppend = "<div class='article-inline-image'>";
				    	toAppend += '<img src="'+ $(el).prop("src") +'" />';
				    	toAppend += "<div class='share-overlay'>";
				    	toAppend += '<span class="share-overlay-title">share</span>';
				    	toAppend += "<ul class='list-inline'>";
				    		toAppend += '<li><a target="_blank" href="https://pinterest.com/pin/create/bookmarklet/?media='+$(el).prop("src")+'&url='+currentURL+'"><i class="fa fa-pinterest"></i></a></li>';
							toAppend += '<li><a target="_blank" href="mailto:?body='+currentURL+'"><i class="fa fa-envelope"></i></a></li>';
							toAppend += '<li><a target="_blank" href="https://twitter.com/intent/tweet?url='+currentURL+'"><i class="fa fa-twitter"></i></a></li>';
							toAppend += '<li><a target="_blank" href="https://www.facebook.com/sharer.php?u='+currentURL+'"><i class="fa fa-facebook"></i></a></li>';
							toAppend += '<li><a target="_blank" href="https://plus.google.com/share?url='+currentURL+'"><i class="fa fa-google-plus"></i></a></li>';
				    	toAppend += "</ul>";
				    	toAppend += "</div>";
				    	toAppend += "</div>";	
				    	$(toAppend).insertAfter($(el));
				    	$(el).remove();
				    });

					$(window).resize(function(){
						$("#mobile-nav").height(window.innerHeight);

						if(window.innerWidth < 767)
						{
							$(".contact-modal .box").height(window.innerHeight - 200);	
						}
						
					});

					$(window).trigger("resize");

					$("#mobile-nav .dropdown").click(function(e){
      					$("#mobile-nav .dropdown").not($(this).parent("li")).removeClass("open");
      					$(this).parent("li").addClass("open");
      				});

      				$(".navbar-toggle").click(function(){
      					$(".body-wrapper").addClass("no-scroll");
      					$("#mobile-nav").addClass("open");
      				});

      				$("#closeMobile").click(function(){
      					$(".body-wrapper").removeClass("no-scroll");
      					$("#mobile-nav").removeClass("open");
      				});

      				$(document).bind('gform_confirmation_loaded', function(event, formId){
					    if(formId == 1)
					    {
					    	$(".newsletter-modal .modal-title").text("Thank You");
					    }
					});


      				$(window).load(function(){

	      				if(window.innerWidth > 767)
	      				{
	      					$(".sticky-ad").stick_in_parent({
		      					offset_top : 150
		      				});	
	      				}

	      				var navbarTop = $("#navbar").offset().top;
	      				var navbarHeight = $("#navbar").outerHeight(true);
	      				var brandImageHeight = $(".navbar-header").height();
	      				var navbarHeaderOffset = brandImageHeight;
	      				var wrapTop = $(".wrap").offset().top;
	      				var scrollPosition;

	      				$(window).scroll(function(){
	      					scrollPosition = $(window).scrollTop();
	      					//console.log(scrollPosition);

	      					if(window.innerWidth > 767)
	      					{
	      						if(scrollPosition >= navbarTop){
		      						$("header").css({"top" : "-" + brandImageHeight +"px"}).addClass("fixed");
		      						$(".wrap").css({'padding-top' : (wrapTop-15)+"px"});
		      					}
		      					else
		      					{
		      						$("header").css({"top" : "auto"}).removeClass("fixed");
		      						$(".wrap").css({'padding-top' : "0"});
		      					}
	      					}
	      					
	      				});


      					
      				});

					$(".shop").click(function(e){
						e.stopPropagation(); e.preventDefault();

						var boxId = $(this).data('toggle');
						$(boxId).toggleClass("showing");
      					$(boxId + " .box").toggleClass("come-up");

					});

					$(".close-insta").click(function(e){
						e.preventDefault();

						$(this).closest(".modal-item").toggleClass("showing");
						$(this).closest(".box").toggleClass("come-up");
					});

					$(".instagram.box").each(function(index, el){
						$(el).find(".product-slider ul").bxSlider({
							mode : "horizontal",
	      					speed : 600,
	      					slideMargin : 15,
	      					infiniteLoop : true,
	      					easing : 'ease-in-out',
	      					useCSS : true,
	      					touchEnabled : true,
	      					pager : false,
	          				autoReload: true,
	          				moveSlides : 1,
	          				startSlide : 0,
	          				auto : autoScroll,
	          				breaks: [{screen:0, slides:1}, {screen:660, slides:3}],
						});
					});
      				
      				$('a[href^="#toggleContact"]').click(function(e) {
						e.preventDefault();

      					$(".contact-modal").toggleClass("showing");
      					$(".contact-modal .box").toggleClass("come-up");
					});

					$('.toggleContact').click(function(e) {
						e.preventDefault();

      					$(".contact-modal").toggleClass("showing");
      					$(".contact-modal .box").toggleClass("come-up");
					});

      				$("body").on('click', '.toggleSearch', function(e){
      					e.preventDefault();

      					$(".search-modal").toggleClass("showing");
      					$(".search-modal .box").toggleClass("come-up");
      				});

      				$("body").on('click', '.toggleNewsletter', function(e){
      					e.preventDefault();

      					$(".newsletter-modal").toggleClass("showing");
      					$(".newsletter-modal .box").toggleClass("come-up");
      				});

      				if(window.innerWidth < 767)
      				{
      					$('body').bind('focusin focus', function(e){
						  	e.preventDefault();
						});
      				}

      				$("#toggleComments").click(function(e){
      					e.preventDefault();
      					$("#respond").slideDown(120);

      					$('html, body').animate({
					        scrollTop: $("#respond").offset().top - 200
					    }, 500);

      				});

      				$(".press-navigation a").each(function(index, el){
      					if(window.location.href == $(el).prop("href"))
      					{
      						$(el).addClass("gold");
      					}
      				});
      				

      				var totalItems = 0;
      				var sliderCardBottom = "";
      				var sliderOffset = 0;

      				if(window.innerWidth > 660)
      				{
      					sliderCardBottom = "30px";
      					sliderOffset = 1;
      				}
      				else
      				{
      					sliderCardBottom = "10px";
      				}

      				if(window.innerWidth > 767)
      				{
      					var autoScroll = false;
      					var bxuseCSS = true;
      					var slideMargin = 0;
      				}
      				else
      				{
      					var autoScroll = true;
      					var bxuseCSS = false;
      					var slideMargin = 0;
      				}

      				var totalItems = 0;
      				var showItems = 1;
      				var margin = 0;
      				var indexOffset = 0;
      				if(window.innerWidth > 767)
      				{
      					showItems = 3;
      					margin = 35;
      					indexOffset = 1;
      				}
      				$(".article-slider").lightSlider({
      					enableDrag:false,
				        item: showItems,
				        autoWidth: false,
				        slideMove: 1, // slidemove will be 1 if loop is true
				        slideMargin: margin,
				        mode: "slide",
				        useCSS: true,
				        cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
				        speed: 800, //ms'
				        auto: false,
				        loop: true,
				        slideEndAnimation: true,
				        centerSlide : true,
				        pager: true,
				        enableTouch:true,
				        responsive : [],
				        
				        onSliderLoad: function (slider) {

				        	if(window.innerWidth < 770)
				        	{
				        		console.log("going to slide 2");
				        		slider.goToSlide(2);
				        	}

	          				totalItems = slider.getTotalSlideCount();
	          				//console.log(slider);

	          				var active_index = jQuery(slider).parent().next().find(".active").index();
						    var bullets = jQuery(slider).parent().next().find("li").length;

						    if (active_index + 1 === bullets) {
						        jQuery(slider).next().children(".lSNext").addClass("goToStart");
						    }

						    if (active_index === 0) {
						        jQuery(slider).next().children(".lSPrev").addClass("goToEnd");
						    }

	          				jQuery(".lSNext").on("click", function(){
							    if (jQuery(this).hasClass("goToStart")) {
							        jQuery(this).parents(".lSSlideWrapper").next().children().first().trigger("click");
							        jQuery(this).removeClass("goToStart");
							    }

							    if (jQuery(this).prev().hasClass("goToEnd")) {
							        jQuery(this).prev().removeClass("goToEnd");
							    }
							});

							jQuery(".lSPrev").on("click", function(){
							    if (jQuery(this).hasClass("goToEnd")) {
							        jQuery(this).parents(".lSSlideWrapper").next().children().last().trigger("click");
							        jQuery(this).removeClass("goToEnd");
							    }

							    if (jQuery(this).next().hasClass("goToStart")) {
							        jQuery(this).next().removeClass("goToStart");
							    }
							});

							if(window.innerWidth > 767)
							{
								var el = $('.article-slider li').not('.clone').eq(active_index+1);
							}
							else
							{
								var el = $('.article-slider li').not('.clone').eq(active_index);
							}
							
          					
          					var currentTitle = el.data("title");
	          				var currentPermalink = el.data("url");
	          				var currentCategory = el.data("category");
	          				var currentTaxonomyLink = el.data("catUrl");

	          				$(".slider-category a").prop("href", currentTaxonomyLink).text(currentCategory);
	          				$(".slider-title a").prop("href", currentPermalink).text(currentTitle);

          					// since we're considering the "middle" item "active", currentIndex is actually +1
          					$(".slider-card").fadeIn(100);
				        },
				        onBeforeSlide: function (slider) {
				        	$(".slider-category a").css({"opacity" : "0"});
	          				$(".slider-title a").css({"opacity" : "0"});

	          				if(window.innerWidth > 767)
	          				{
	          					$(".lSPrev").css({"opacity" : "0"});
	          					$(".lSNext").css({"opacity" : "0"});	
	          				}
	          				
				        },

				        onAfterSlide: function(slider){
				        	var active_index = jQuery(slider).parent().next().find(".active").index();
						    var bullets = jQuery(slider).parent().next().find("li").length;

				        	if (active_index + 1 === bullets) {
						        jQuery(slider).next().children(".lSNext").addClass("goToStart");
						    }

						    if (active_index === 0) {
						        jQuery(slider).next().children(".lSPrev").addClass("goToEnd");
						    }

						    //console.log(active_index);

						    var activeSliderIndex;
							if(active_index + 1 === bullets)
							{
								//console.log("Think we're on the last item!");
								if(window.innerWidth > 767)
								{
									activeSliderIndex = 0;
								}
								else
								{
									activeSliderIndex = active_index+(indexOffset);
								}
								
							}
							else
							{
								activeSliderIndex = active_index+(indexOffset);
							}

							var el = $('.article-slider li').not('.clone').eq(activeSliderIndex);
          					
          					var currentTitle = el.data("title");
	          				var currentPermalink = el.data("url");
	          				var currentCategory = el.data("category");
	          				var currentTaxonomyLink = el.data("catUrl");


	          				window.setTimeout(function(){
	          					// now reset the links and titles and what not
	          					$(".slider-category a").prop("href", currentTaxonomyLink).text(currentCategory);
	          					$(".slider-title a").prop("href", currentPermalink).text(currentTitle);
	          				}, 20);


	          				window.setTimeout(function(){
	          					// reset all of the properties
		          				$(".slider-category a").animate({"opacity" : "1"}, 75);
								$(".slider-title a").animate({"opacity" : "1"}, 75);

								$(".lSPrev").animate({"opacity" : "1"}, 75);
	          					$(".lSNext").animate({"opacity" : "1"}, 75);
								
	          				}, 50);
				        }
				    });

      				/*var slideHeight = (window.innerWidth / 3) * 1.2;

      				$(".article-slider").bxSlider({
      					mode : "horizontal",
      					speed : 600,
      					slideMargin : slideMargin,
      					infiniteLoop : true,
      					easing : 'ease-in-out',
      					useCSS : true,
      					touchEnabled : true,
      					pager : false,
          				autoReload: true,
          				moveSlides : 1,
          				startSlide : 0,
          				auto : autoScroll,
          				breaks: [{screen:0, slides:1}, {screen:660, slides:3}],

          				onSliderLoad : function(currentIndex){
          					
          					//get the current element
          					var el = $(".article-slider li").not('.bx-clone').eq(currentIndex+sliderOffset);
          					
          					$(".article-slider li.center").removeClass("center");

          					el.addClass("center");
          					
          					var currentTitle = el.data("title");
	          				var currentPermalink = el.data("url");
	          				var currentCategory = el.data("category");
	          				var currentTaxonomyLink = el.data("catUrl");
	          				totalItems = $(".article-slider li").not('.bx-clone').length;

	          				$(".slider-category a").prop("href", currentTaxonomyLink).text(currentCategory);
	          				$(".slider-title a").prop("href", currentPermalink).text(currentTitle);


          					// since we're considering the "middle" item "active", currentIndex is actually +1

          					$(".bx-controls").fadeIn(100);
          					$(".slider-card").fadeIn(100);

          				},
          				onSlideBefore : function($slideElement, oldIndex, newIndex){
          					//console.log("hiding");
          					$(".article-slider-wrapper .bx-prev").css({"opacity" : "0"});
          					$(".article-slider-wrapper .bx-next").css({"opacity" : "0"});

          					var el;
          					if((newIndex+sliderOffset) == totalItems)
          					{
          						el = $(".article-slider li").not('.bx-clone').eq(0);
          					}
          					else
          					{
          						el = $(".article-slider li").not('.bx-clone').eq(newIndex+sliderOffset);
          					}
          					
          				
          					$(".article-slider li.center").removeClass("center");

          					el.addClass("center");
          					
          					var currentTitle = el.data("title");
	          				var currentPermalink = el.data("url");
	          				var currentCategory = el.data("category");
	          				var currentTaxonomyLink = el.data("catUrl");

	          				
	          				$(".slider-category a").animate({"opacity" : "0"}, 75);
	          				$(".slider-title a").animate({"opacity" : "0"}, 75);

	          				window.setTimeout(function(){
	          					// now reset the links and titles and what not
	          					$(".slider-category a").prop("href", currentTaxonomyLink).text(currentCategory);
	          					$(".slider-title a").prop("href", currentPermalink).text(currentTitle);
	          				}, 220);


	          				window.setTimeout(function(){
	          					// reset all of the properties
		          				$(".slider-category a").animate({"opacity" : "1"}, 75);
								$(".slider-title a").animate({"opacity" : "1"}, 75);
	          				}, 300);
          						
          				},
          				onSlideAfter : function($slideElement, oldIndex, newIndex){
          					//console.log("showing");
          					$(".article-slider-wrapper .bx-prev").css({"opacity" : "1"});
          					$(".article-slider-wrapper .bx-next").css({"opacity" : "1"});
          				}
      				});*/



					$(".date-slider").bxSlider({
      					mode : "horizontal",
      					speed : 350,
      					slideMargin : 0,
      					infiniteLoop : false,
      					hideControlOnEnd : true,
      					//easing : 'ease-in-out',
      					useCSS : true,
      					touchEnabled : true,
      					pager : false,
          				autoReload: true,
          				moveSlides : 1,
          				startSlide : 0,
          				//breaks: [{screen:760, slides:7, moveSlides:1}],
      				});

					var showItems = 0;
					var margin = 0;
      				if(window.innerWidth < 767)
      				{
      					showItems = 1;
      					margin = 0;
      				}
      				else
      				{
      					showItems = 4;
      					margin = 0;
      				}


      				$(".collection-slider").lightSlider({
      					enableDrag:false,
				        item: showItems,
				        autoWidth: false,
				        slideMove: 1, // slidemove will be 1 if loop is true
				        slideMargin: margin,
				        mode: "slide",
				        useCSS: true,
				        cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
				        speed: 400, //ms'
				        auto: false,
				        loop: true,
				        slideEndAnimation: true,
				        centerSlide : true,
				        pager: true,
				        enableTouch:true,
				        responsive : [],
				    });

      				/*$(".collection-slider").bxSlider({
      					mode : "horizontal",
      					speed : 350,
      					slideMargin : 0,
      					infiniteLoop : true,
      					hideControlOnEnd : true,
      					//easing : 'ease-in-out',
      					useCSS : true,
      					touchEnabled : true,
      					pager : false,
          				autoReload: true,
          				moveSlides : 1,
          				startSlide : 0,
          				breaks: [{screen:0, slides:1, controls:false}, {screen:760, slides:2, controls:false}, {screen:992, slides:4}],
      				});
					*/

      				
      				$(".comment-metadata a, .comment-author a").click(function(e){
      					e.preventDefault();
      				});

      				$(".comment-reply-link").click(function(e){
      					e.preventDefault();
      					$("#toggleComments").click(); 
      				});
				
					$("#comment").val('').prop('placeholder', 'Comment');
					$("#author").val('').prop('placeholder', 'Name');
					$("#email").val('').prop('placeholder', 'Email Address');



					var biggestColumn = $(".wishlist-column-items").eq(0); 
					var smallestColumn = $(".wishlist-column-items").eq(0); 
					var currentDifference = 0;
					var currentItem;
					var lastItemHeight;
					var differenceThreshold;

					if(window.innerWidth > 992)
					{
						differenceThreshold = 450;
					}
					else if(window.innerWidth < 992 && window.innerWidth > 767)
					{
						differenceThreshold = 420;
					}
					else
					{
						differenceThreshold = 100000;					
					}

					do{
						// this .each() loop get's the largest and smallest columns, and their difference in height
						if($(".wishlist-column-items").length < 10)
						{
							// if there are only a few items, dont even run this
							break;
						}

						$(".wishlist-column-items").each(function(index, el){

							if($(el).height() > biggestColumn.height())
							{
								biggestColumn = $(el);
							}

							if($(el).height() < smallestColumn.height())
							{
								smallestColumn = $(el);
							}

							
							currentDifference = parseInt(biggestColumn.height() - smallestColumn.height());
							//console.log(currentDifference);
						});
						// end .each() loop

						// now, if greatest difference greater than 800
						// pull item from largest column and add to smallest

						// all testing logs
						/*
						console.log("Biggest:");
						console.log(biggestColumn);
						console.log("Smallest");
						console.log(smallestColumn);
						

						console.log(biggestColumn.height());
						console.log(smallestColumn.height());
						console.log("Current largest difference is " + currentDifference);
						*/

						if(currentDifference > differenceThreshold)
						{
							currentItem = $(biggestColumn).find(".wishlist-item").last();
							lastItemHeight = $(biggestColumn).find(".wishlist-item").last().height();
							$(biggestColumn).find(".wishlist-item").last().remove();
							$(smallestColumn).append(currentItem);
						}

						if(lastItemHeight > differenceThreshold)
						{
							break;
						}

					} while(currentDifference > differenceThreshold);


					$(window).resize(function(){
						if(window.innerWidth < 990)
						{
							// on touch devices
							$(".instagram-item-overlay").click(function(e){
								e.preventDefault();
							});
						}
					});

					$(window).trigger("resize");


      			});
      		}
    	},
    
    	// Home page
    	'home': {
      		init: function() {
        		// JavaScript to be fired on the home page
      		}
    	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
